import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const employeesApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().rootReducer.logInToken.data.token;
			// If we have a token set in state, let's assume that we should be passing it.
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}

			return headers;
		},
	}),
	tagTypes: ["Employee"],
	endpoints: (builder) => ({
		getAllEmployees: builder.query({
			query: (params) => ({
				url: "retailerAdmin/employees/get-all",
				method: "GET",
				params: params,
			}),
			providesTags: ["Employee"],
		}),
	}),
});

export const { useGetAllEmployeesQuery } = employeesApi;
