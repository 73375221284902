import React, { Component } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ResetPassword")
);
const CompleteProfile = React.lazy(() =>
  import("./views/pages/register/profile")
);
const CompleteEmailVerification = React.lazy(() =>
  import("./views/pages/register/otp")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
// const changePassword = React.lazy(() =>
//   import("./pages/Profile/changePassword")
// )
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      loading: true,
    };
  }
  conmponentDidMount() {
    const isLoggedIn = localStorage.getItem("retailerAdmin");
    if (isLoggedIn) {
      this.setState({
        loggedIn: isLoggedIn,
      });
    } else {
      this.setState({
        loggedIn: false,
      });
    }
  }
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/reset-forgot-password"
              name="Reset Forgot Password Page"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/complete-profile"
              name="CompleteProfile"
              component={CompleteProfile}
            />
            <Route
              exact
              path="/complete-email-verification"
              name="CompleteEmailVerification"
              component={CompleteEmailVerification}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
