import { combineReducers } from "redux";
import logInReducer from "./loginReducer";
import adminReducer from "./AdminReducer";
import employeeReducer from "./EmployeeReducer";
import retailerProductReducer from "./RetailerProductReducer";
import profilePictureHeaderReducer from "./ProfilePictureHeaderReducer";
import changeState from "./changeState";

const rootReducer = combineReducers({
  logInToken: logInReducer,
  sidebarShow: changeState,
  adminData: adminReducer,
  employeeData: employeeReducer,
  profilePictureHeaderData: profilePictureHeaderReducer,
  retailerProductData: retailerProductReducer,
});

export default rootReducer;
