import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import rootReducer from "../reducer";
import thunkMiddleware from "redux-thunk";
import { employeesApi } from "src/services/employee.service";
import { retailerProductsApi } from "src/services/retailerProduct.service";

const newReducer = combineReducers({
  rootReducer: rootReducer,
  // [employeesApi.reducerPath]: employeesApi.reducer,
  // [retailerProductsApi.reducerPath]: retailerProductsApi.reducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  newReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware,
    //  employeesApi.middleware, retailerProductsApi.middleware
     ))
);


export default store;
