import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const retailerProductsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}product/`, prepareHeaders: (headers, { getState }) => {
      const token = getState().rootReducer.logInToken.data.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  tagTypes: ['RetailProduct'],
  endpoints: (builder) => ({
    getAllRetailerProducts: builder.query({
      query: () => ({
        url: "getAll",
        method: 'GET',
      }),
      providesTags: ['RetailProduct']
    }),
  }),
});

export const { useGetAllRetailerProductsQuery } = retailerProductsApi;
